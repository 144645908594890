import './products.css';
// import banner from '../static/main-image.png';
import banner_2 from '../static/banner-2-1.svg';
import bannerLap from '../static/banner-2-lap.svg';
import bannerPhone from '../static/banner-2-phone.svg';
import bannerTab from '../static/banner-2-tab.svg';
// import LearnVector from '../static/banner-2-learn.svg'
import APIVector from '../static/banner-2-API.svg'
import APIGETVector from '../static/banner-2-API-get.svg'
import APIPOSTVector from '../static/banner-2-API-post.svg'
// import brandlogo from '../static/enc.svg';
import vector1 from '../static/ring base-shield-3.svg';
// import vector2 from '../static/ring base-vault.svg';
import vector2 from '../static/ring base-vault-2.svg';
// import vector3 from '../static/ring base-ui.svg';
// import vector4 from '../static/ring base-ai.svg';
import vector4 from '../static/ring base-shield-threads.svg';
import vector5 from '../static/ring base-E8-1.svg';
import vector6 from '../static/ring base-cred.svg';
import vector7 from '../static/ring base-gen.svg';
import vector8 from '../static/ring base-thoughtlock.svg';
import vector9 from '../static/ring base-strength.svg';
import vector10 from '../static/ring base-age.svg';

import VectorPlan from '../static/banner-Plans.svg' 
import Vectorstarted from '../static/banner-Start.svg' 

import { SetTitle, ShowCase } from '../components/utility';
import { ToTop, ImageCarousel } from '../components/utility';
import { NavLink } from 'react-router-dom';

function Products() {
    SetTitle('Encrypta.in - Solutions')

    return (
        <>
            <ToTop></ToTop>
            <div className="Product_Banner-container">
                <div className="banner-body">
                    <h1 className='Banner-title'>Solutions</h1>
                    <div className="Banner-content">
                        {/* Encrypta Solutions got your back. */}
                        Get burdens off your shoulders with Encrypta solutions.
                        {/* Solutions for everyone, anytime, anywhere. */}
                    </div>
                    {/* <button className="btn btn-main btn-1"></button> */}
                </div>
                <div className="banner-vector">
                    <ImageCarousel images={[banner_2]}></ImageCarousel>
                </div>
            </div>
            <div className="Product_item-Content-Wrapper">
                <div className="Product_item-title">
                    <h1>
                        Keep your Safety in check with Encrypta.
                    </h1>
                </div>
                <div className="Product_item-container">
                    <div className="Product_item-body">
                        {/* <div className="Product_item-title">
                        </div> */}
                        <div className="Product_item-heading">
                            <h1>Password Manager</h1>
                            {/* <h1>Be the change.</h1> */}
                        </div>
                        {/* enjoy standing at the edge of the cliff.  */}
                        Days of troubling yourself remembering credentials are long gone with Encrypta Password Manager.
                        {/* Keep your Daily life */}
                        <NavLink className="Product_item-link" to={'PasswordManager'}>
                            <button className="btn-main btn btn-1">Learn More</button>
                            {/* <button className="btn-main btn btn-5">Learn More</button> */}
                        </NavLink>
                    </div>
                    <div className="Product_item-vector">
                        <img src={bannerPhone} className='vector' alt="" />
                    </div>
                </div>
                {/* <ShowCase Title={'Password Manager'} Image={bannerTab} Content={'Password Manager brought to you by Encrypta. for you, for the ones you love, for the ones who matter. because you deserve it.div'}></ShowCase> */}
            </div>
            <div className="Product_item-Content-Wrapper">
                <div className="Product_item-title">
                    <h1>
                        Develop With Encrypta Console.
                    </h1>
                </div>
                <div className="Product_item-container">
                    <div className="Product_item-body">
                        <div className="Product_item-heading">
                            <h1>Authentication Made simpler With Encrypta.</h1>
                        </div>
                        User Authentication Made Hassle-Free and even more secure with Encrypta AUTH.
                        {/* <NavLink className="Product_item-link" to={'PasswordManager'}> */}
                        <div className="Product_item-link"  >
                            <button disabled className="btn-main btn btn-1">Coming Soon</button>
                        </div>
                        {/* <button className="btn-main btn btn-5">Learn More</button> */}
                        {/* </NavLink> */}
                    </div>
                    <div className="Product_item-vector">
                        <img src={bannerTab} className='vector' alt="" />
                    </div>
                </div>
                <div className="Product_item-container">
                    <div className="Product_item-body">
                        <div className="Product_item-heading">
                            <h1>Encrypta Console</h1>
                        </div>
                        Make wonders happen with your projects. Build and empower your project with Encrypta console. 
                        {/* Do what you are best at and leave the rest to us. Make your wonders come true with the help of encrypta console solutions. */}
                        {/* <NavLink className="Product_item-link"  to={''}> */}
                        <div className="Product_item-link"  >
                            <button disabled className="btn-main btn btn-1">Coming Soon</button>
                        </div>
                        {/* </NavLink> */}
                    </div>
                    <div className="Product_item-vector">
                        <img src={bannerLap} className='vector' alt="" />
                    </div>
                </div>
                {/* <div className="Product_item-container">
                    <div className="Product_item-body">
                        <div className="Product_item-heading">
                            <h1>Development Made simpler With Encrypta.</h1>
                        </div>
                        Make Wonder Come to Reality With Encrypta Developer Support Teams.

                        <NavLink className="Product_item-link" to={'PasswordManager'}>
                        <div className="Product_item-link">
                            <button disabled className="btn-main btn btn-1">Comming soon</button>
                        </div>
                        </NavLink>
                    </div>
                    <div className="Product_item-vector">
                        <img src={bannerLap} className='vector' alt="" />
                    </div>
                </div> */}
            </div>
            <div className="Product_item-Content-Wrapper">
                <div className="Product_item-title">
                    <h1>
                        Protect With Encrypta.
                    </h1>
                </div>
                <div className="Product_item-container">
                    <div className="Product_item-body">
                        <div className="Product_item-heading">
                            <h1>Leave Data safety to us.</h1>
                        </div>
                        With Encrypta API, Secure your Data with Encrypta State of the Art Encryption techniques.
                        {/* <NavLink className="Product_item-link"> */}
                        <div className="Product_item-link">
                            <button disabled className="btn-main btn btn-1">Coming Soon</button>
                            {/* <button className="btn-main btn btn-5">Learn More</button> */}
                        </div>
                        {/* </NavLink> */}
                    </div>
                    <div className="Product_item-vector">
                        {/* <ImageCarousel seconds={5} images={[APIGETVector, APIPOSTVector]}></ImageCarousel> */}
                        <img src={APIVector} className='vector' alt="" />
                    </div>
                </div>
            </div>
            {/* <div className="Product_item-Content-Wrapper">
                <div className="Product_item-title">
                    <h1>
                        Learn With Encrypta.
                    </h1>
                </div>
                <div className="Product_item-container">
                    <div className="Product_item-body">
                        <div className="Product_item-heading">
                            <h1>Learning Opportunities at Encrypta.</h1>
                        </div>
                        Be the ONE standing out from the crowd. Explore Learning Opportunities at Encrypta.
                        <NavLink className="Product_item-link" to={'PasswordManager'}>
                            <button className="btn-main btn btn-1">Learn More</button>
                        </NavLink>
                    </div>
                    <div className="Product_item-vector">
                        <img src={LearnVector} className='vector' alt="" />
                    </div>
                </div>
            </div> */}
        </>
    )
}



function PasswordManager() {
    const ProducthowcaseItem = [
        { Title: 'Uncompromising Security and Encryption: Your Data\'s Guardian!', Body: 'Ensuring the security of your data is our top priority. We employ state-of-the-art security measures and cutting-edge encryption protocols to make sure that your sensitive information is kept strictly confidential. Your data is shielded from unauthorized access and cyber threats by our revolutionary encryption technology, which acts as an impenetrable barrier.  Rest easy knowing that your valuable information is in safe hands, guarded by our Encryption\'s uncompromising commitment to your privacy and security.', Vector: vector1 },
        { Title: 'Effortless Credential Storage for a Hassle-Free and Safe Experience!', Body: 'Juggling multiple usernames and passwords for various online platforms is something you may be getting tired of? We have just the ideal solution for you. Now save all of your login information in one handy place with the utmost Security. The days of having to remember complicated passwords, struggling to recall them or creating a Simple One instead are long gone. With just one click, you can seamlessly access your favorite websites and applications, saving you time and relieving you of the hassle of managing passwords.', Vector: vector6 },
        { Title: 'Shared Vault: Share the Un-sharables!', Body: "Sharing Unsharables made simple! Password Manager Vaults make it incredibly easy to share your Secured Data even more securely. Share the Unsharables with colleagues, friends, or family, knowing that your data stays Secured. Experience the convenience of hassle-free Sharing while maintaining full control over who can access your shared content.", Vector: vector2 },
        { Title: 'Keep Your Thoughts Under Lock and Key!', Body: "You deserve to have your thoughts and ideas protected and in complete privacy. With Encrypta Secured Notes, You can protect your private and professional musings from prying eyes. Your notes are transformed into an impenetrable fortress that is only accessible to you by using Encrypta Revolutionary encryption Methods. Whether it's private thoughts, imaginative ideas, or sensitive information, you can rely on our app to protect them all and keep them under your control.", Vector: vector8 },
        { Title: 'Unleash the Power of AI with Our Password Generator!', Body: "Having trouble coming up with secure and memorable passwords? Look no further! Your online security will be revolutionized by our AI-based password generator. Our password generator generates strong, virtually uncrackable passwords that are suited to your specific needs using cutting-edge artificial intelligence algorithms. Say <font style=\"font-family: 'Pacifico', cursive; font-size: 1.2rem\">alavida</font> to the era of simple and guess - able passwords and <font style =\"font-family: 'Pacifico', cursive; font-size: 1.2rem\">welcome</font> the security and peace of mind that comes from knowing that your digital accounts are protected by the encrypta security.", Vector: vector7 },
        { Title: 'Limitless Storage: Store Your Data Without Boundaries with Encrypta 8!', Body: "Embrace the freedom of limitless storage with our app. Say goodbye to space restrictions and limitations because our platform provides you with plenty of room to store all of your data with Encrypta 8 storage and safety solutions. Experience the convenience of having all your data at your fingertips, ready to be accessed whenever and wherever you need it.", Vector: vector5 },
        { Title: 'Revolutionary AI Encryption: Unleash Next-Level Protection!', Body: "Discover the cutting-edge strength of our ground-breaking AI Encryption technology. Our encryption protocols, with artificial intelligence at their core, offer unparalleled protection for your sensitive data. Our advanced algorithms create unbreakable encryption, ensuring that your information remains confidential and inaccessible to unauthorized parties. Trust in the revolutionary abilities of our app's AI Encryption and take pleasure in the security that comes with cutting-edge data security.", Vector: vector4 },
        { Title: 'Password Strength AI: Your Personal Security Advisor!', Body: "Strengthening your online security has never been easier with our AI-based Password Strength feature. By assessing your passwords' strength and providing real-time feedback, Encrypta AI never fails to serve you as your personal security advisor. AI analyzes various factors like complexity, uniqueness, and length to ensure that your passwords are strong and resilient against any hacking attempts. So <font style=\"font-family: 'Pacifico', cursive; font-size: 1.2rem\">alavida</font> to weak passwords and hello to a new era of reliable online security.", Vector: vector9 },
        // {Title: 'Encrypta AUTH Factor Authentication: Lock Out Unauthorized Access!', Body: "Protect your accounts like never before with Encrypta AUTH Factor Authentication. Your login process is made more secure by Encrypta AUTH, which demands additional verification steps in addition to your username and password. Whether it's a fingerprint scan, facial recognition, or a unique code sent to your mobile device, Encrypta AUTH ensures that only <font style=\"font-family: 'Pacifico', cursive; font-size: 1.2rem\">You</font> can access your account. Say good-bye to unauthorized access and take pleasure in the security-assured peace of mind.", Vector: vector1},
        { Title: 'Stay Ahead with Password Age Monitoring!', Body: "Stay one step ahead of potential security breaches with the Password Age Monitoring. Password Manager keeps track of the age of your passwords and notifies you when it's time to update. You can significantly lower the risk of unauthorized access to your accounts by routinely changing your passwords more frequently. Let Encrypta Password Manager be your proactive security companion, ensuring that your digital fortress remains impenetrable at all times.", Vector: vector10 },
        // { Title: 'User-Friendly and Intuitive: Your Data Security Made Simple!', Body: "We believe everyone should experience the ground-breaking data Security technology, Regardless of technical proficiency. Encrypta Password Manager is designed with a user-friendly interface, ensuring that protecting your Data is as simple as a few taps. Our intuitive design and streamlined features make data security a breeze, empowering you to safeguard your valuable information effortlessly. Experience the perfect balance of power and simplicity, putting your data security within your grasp.", Vector: vector3 },
    ]



    function Showcase() {
        var Case = []
        ProducthowcaseItem.forEach((element, i) => {
            Case.push(
                <ShowCase key={'PasswordManager Feature-' + i} Title={element.Title} Image={element.Vector} Content={element.Body}></ShowCase>
            )
        });
        return (
            <>
                {Case}
            </>
        )
    }

    SetTitle('Encrypta.in - Password Manager')

    return (
        <>
            <ToTop></ToTop>
            <div className="Product_Banner-container">
                <div className="banner-body">
                    {/* <div className="logo-container">
                        <object type="image/svg+xml" data={brandlogo} className="brand-logo">
                            Encrypta.in
                        </object>
                    </div> */}
                    <h1 className='Banner-title'>Password Manager</h1>
                    <div className="Banner-content">
                        Never Bother remembering your credentials again with Encrypta Password Manager.
                    </div>
                </div>
                <div className="banner-vector">
                    <ImageCarousel seconds={5} images={[banner_2, bannerLap, bannerPhone, bannerTab]}></ImageCarousel>
                </div>
            </div>
            <Showcase></Showcase>

            <div className="Product-summary-wrapper" >
                <div className="summary-head">
                    <span>
                        <h1>Password Manager by Encrypta</h1>
                    </span>
                    <span>
                        <p style={{ 'fontWeight': 600, margin: '0.2rem 0' }}>Get Started with Encrypta Password Manager</p>
                    </span>
                </div>
                <div className="summary-content">
                    <div className="summary-vector-wrapper">
                        <img src={VectorPlan} alt="Home_dialogue-img" className='Home_dialogue-img' />

                    </div>
                    <div className="summary-text">
                        <h2>Choose the Plan that suits you.</h2>
                        {/* <p>Explore our extensive selection of products, thoughtfully curated to suit your everyday requirements.</p> */}
                        <p>Indulge in the vast assortment of products we offer, as you embark on a journey of exploration through our meticulously curated plans, designed to cater to your diverse requirements. With an extensive range of carefully Curated variety of plans and product combinations, we strive to present you with an array of options that seamlessly align with your everyday requirements.</p>

                        <a className="Product_item-link" target='_blank' rel='noreferrer' href='http://accounts.encrypta.in/Account/'>
                            <button className="btn-main btn btn-1">Products</button>
                        </a>
                    </div>
                </div>
                <div className="summary-content">
                    
                <div className="summary-vector-wrapper">
                        <img src={Vectorstarted} alt="Home_dialogue-img" className='Home_dialogue-img' />
                    </div>
                    <div className="summary-text">
                        <h2>All Set Up?</h2>
                        {/* <p>Talked Plenty about Password Manager by Encrypta, Experience yourself by getting Started with your ultimate companion, curated just for you to make your life easier and safer.</p> */}
                        <p>Talked Plenty about Password Manager by Encrypta, Take the first step and get started with your ultimate companion, thoughtfully curated to simplify and enhance your life, while ensuring optimal security. Experience the transformative power of Encrypta as it becomes your trusted everyday companion.</p>
                        <a className="Product_item-link" target='_blank' rel='noreferrer' href='https://passwordmanager.encrypta.in'>
                            <button className="btn-main btn btn-1">Get Started</button>
                        </a>
                    </div>
                </div>
            </div>

        </>
    )
}


function API() {
    SetTitle('Encrypta.in - Solutions - APIs')

    return (
        <>
            <ToTop></ToTop>
            <div className="Product_Banner-container">
                <div className="banner-body">
                    <h1 className='Banner-title'>API</h1>
                    <div className="Banner-content">
                        {/* Be the Watcher of your Realms. */}
                        Your GateWay to Safety
                    </div>
                    {/* <button className="btn btn-main btn-1"></button> */}
                </div>
                <div className="banner-vector">
                    <ImageCarousel seconds={10} images={[APIGETVector, APIPOSTVector, APIVector]}></ImageCarousel>
                </div>
            </div>
        </>
    )
}







export { Products, PasswordManager, API }