// import { NavLink } from 'react-router-dom';
import './footer.css'


function Footer() {
    return (
        <div className="Footer_container-wrapper">
            <div className="Footer_container">
                {/* <div className="Footer_link-wrap">
                    <div className="Footer_link-container">
                        <NavLink to={'Career'} className="Footer_link">
                            Career
                        </NavLink>
                        <NavLink to={'Learn'} className="Footer_link">
                            Learn
                        </NavLink>
                    </div>
                    <div className="Footer_link-container">
                        <NavLink to={'Teams'} className="Footer_link">
                            Team
                        </NavLink>
                        <NavLink to={'Collaboration'} className="Footer_link">
                            Collaborations
                        </NavLink>
                    </div>
                </div> */}
                <div className="Footer_branding-wrap">
                    <div className="Footer_branding-container">
                        Encrypta.in Media
                    </div>
                    <div className="Footer_social-link-container">
                        <a href="https://www.linkedin.com/company/encrypta-in/" target="_blank" rel='noreferrer' className="Footer_social-link"><i className="fa fa-linkedin"></i></a>
                        <a href="https://www.instagram.com/encrypta.in/" target="_blank" rel='noreferrer' className="Footer_social-link"><i className="fa fa-instagram"></i></a>
                        <a href="https://twitter.com/EncryptaIn" target="_blank" rel='noreferrer' className="Footer_social-link"><i className="fa fa-twitter"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;