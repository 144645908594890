import './home.css'
import Vector from '../static/Container.png'
// import brandlogo from '../static/enc.svg';
// import banner from '../static/main-image.png';
// import banner from '../static/banner-2.svg';
// import banner from '../static/banner-2.png';
import banner from '../static/banner-2-1.png';
import { ContainerCarousel, ImageCarousel, Container_ImageCarousel, SetTitle } from '../components/utility';
import AssuranceVector from '../static/banner-Assurance.svg'
import CostVector from '../static/banner-Price.svg'
// import banner_2 from '../static/banner-2-1.svg'
import bannerLap from '../static/banner-2-lap.svg'
import bannerTab from '../static/banner-2-tab.svg'
// import bannerPhone from '../static/banner-2-phone.svg'



import VectorTrust from '../static/banner-2-colab.svg'

// import banner from '../static/banner.svg';


var base = '/';




function Home() {
    SetTitle('Encrypta.in')
    var BannerCarouselObject = [
        // { Body: { Title: 'Encrypta Console', Body: '<p>Easing your burdens. So that you remain focused on what matters to you.</p>' }, Vector: banner_2 },
        {Body:  {Title:'Password Manager', Body: '<p>Never Bother remembering your Credetial<b>s</b> with Encrypta Password Manager.</p>', Link: {URL: '/Solutions/PasswordManager', Text: 'Learn More'}}, Vector: bannerTab},
    ]
    var NewCarouselObject = [
        {Body:  {Title:'Password Manager', Body: '<p>Never Bother remembering your Credetial<b>s</b> with Encrypta Password Manager.</p>', Link: {URL: '/Solutions/PasswordManager', Text: 'Learn More'}}, Vector: bannerTab},
        // { Body: { Title: 'Encrypta Console', Body: '<p>Take Over the matters to your hands with the support of Encrypta. Build and Empower your projects with Encrypta.</p>' }, Vector: banner_2 },
        { Body: { Title: 'Encrypta Console', Body: '<p>Take Over the matters to your hands with the support of Encrypta. Build and Empower your projects with Encrypta.</p>' }, Vector: bannerLap },
    ]

    return (
        <>
            {/* <div className="Product_Banner-container">
                <div className="banner-body">
                    <div className="logo-container">
                        <object type="image/svg+xml" data={brandlogo} className="brand-logo">
                            Encrypta.in
                        </object>
                    </div>
                    <h1 className='Banner-title'>Encrypta.in</h1>
                    <div className="Banner-content">
                        Let there be Encrypta with you.
                    </div>
                </div>
                <div className="banner-vector">
                    <img src={banner} alt="" className="banner" />
                </div>
            </div> */}
            <div className="Product_Banner-container">
                <ContainerCarousel seconds={10} Elements={BannerCarouselObject} marker={false}></ContainerCarousel>
            </div>
            <div className="Home_container">
                <div className="Home">
                    <h1>Decrypting Encrypta</h1>
                    <span>
                        <p>Encrypta stands resolute as a beacon of assurance in our quest for a safer cyber landscape. This collective of bright minds harmoniously amalgamates their talents and efforts, diligently striving to foster a sense of security in the virtual world for every global denizen.</p><p style={{ 'fontWeight': 600, margin: '0.8rem 0' }}>Welcome to Encrypta, a place where our shared goal becomes reality.</p>
                    </span>
                </div>
                <div className="Home_dialogue">
                    <div className="Home_dialogue-text">
                        {/* <h1 className="Home_dialogue-head">Encrypta: Empowering the Digital Realm with Unyielding Cyber Security</h1> */}
                        <h2>Safeguarding the Digital Realm: Encrypta's Commitment!</h2>
                        {/* <h1 className="Home_dialogue-head">"Fortifying the Digital Frontier: Encrypta's Unyielding Commitment to Cyber Security"</h1> */}
                        <p className="Home_dialogue-para">Encrypta's unwavering commitment to protecting the digital realm is rooted in a deep understanding of the growing importance that cyber security holds in our ever-changing world. As technology advances at a relentless pace, the task of safeguarding our digital identities and preserving our personal information from cunning cyber threats has evolved into an undeniable necessity.</p>
                    </div>
                    <div className="Home_vector-wrapper">
                        <img src={AssuranceVector} alt="Home_dialogue-img"
                            className='Home_dialogue-img' />

                    </div>
                </div>
                <div className="Home">
                    <h1>Our Concern</h1>
                    {/* <p>Trust is an indispensable element when it comes to security, as it directly impacts your valuable digital assets. Unfortunately, numerous existing password managers fall short in transparency regarding their password-saving mechanisms. We understand that your passwords are the guardians of your online identity and must be stored securely. With this in mind, we have implemented state-of-the-art measures to ensure the utmost protection against unauthorized access.</p> */}
                    <span>

                        <p>In a world where cyber threats loom large, trust plays a paramount role. At Encrypta, we've made it our mission to guarantee the absolute protection of your digital identity and well-being. We implement state-of-the-art encryption technology that forms an impenetrable fortress around your data, ensuring the security of your personal information and preserving your digital identity, offering you unmatched protection. With our utmost efforts directed towards your safety, you can rest assured knowing that our best minds in cyber defenses are at the forefront.</p><p style={{ 'fontWeight': 600, margin: '0.8rem 0' }}>Don't compromise on security - choose Encrypta and conquer the digital landscape with confidence. </p>
                    </span>

                </div>
                <div className="Home_dialogue">
                    <div className="Home_dialogue-text">
                        <h1 className="Home_dialogue-head">Price</h1>
                        <p className="Home_dialogue-para">We firmly believe that protecting your digital identity should always come with a moderate price tag. Consequently, we have made it our unwavering commitment to developing a password manager that not only surpasses expectations in terms of performance but also offers an array of exceptional features.</p>
                    </div>
                    <div className="Home_vector-wrapper">
                        <img src={CostVector} alt="Home_dialogue-img"
                            className='Home_dialogue-img' />

                    </div>
                </div>
                <div className="Home_dialogue">
                    <div className="Home_dialogue-text">
                        <h1 className="Home_dialogue-head">Trust</h1>
                        <p className="Home_dialogue-para">
                            Insufficient internet security and privacy awareness are significant challenges that impact many users. Its repercussions encompass leaving individuals susceptible to cyber threats while jeopardizing their online identity and digital presence. Recognizing that not all users possess an innate technological prowess, we have diligently crafted user-friendly and intuitive interfaces specifically designed to streamline the task of safeguarding and managing passwords with utmost ease and convenience.
                        </p>
                    </div>
                    <div className="Home_vector-wrapper">
                        <img src={VectorTrust} alt="Home_dialogue-img"
                            className='Home_dialogue-img' />
                    </div>
                </div>
                {/* <div className="Home_dialogue">
                    <div className="Home_vector-wrapper">
                    <img src={Vector} alt="Home_dialogue-img"
                        className='Home_dialogue-img' />

                    </div>
                    <div className="Home_dialogue-text">
                        <h1 className="Home_dialogue-head">Lack Of Knowledge</h1>
                        <p className="Home_dialogue-para">Lack of knowledge about internet security and privacy is a significant issue that affects many users. It can leave them vulnerable to cyber threats and can compromise their online identity and presence. We understand that not everyone is tech-savvy, so we provide user-friendly and straightforward interfaces that simplify managing and protecting passwords</p>
                    </div>
                </div> */}

                <div className="Home">
                    <h1>What are we thinking today?</h1>
                    <span>
                        <p>At Encrypta, our teams come up with solutions every day to make your daily lifestyle easy and burden-free.<br /> Stay miles ahead with us.</p><p style={{ 'fontWeight': 600, margin: '0.8rem 0' }}>So, What we brought you today? </p>
                    </span>
                    <div className="Home_Showcase-container">
                        <ContainerCarousel seconds={7} Elements={NewCarouselObject} marker={false}></ContainerCarousel>
                    </div>
                </div>
            </div>
        </>
    );
}






export {Home, base}