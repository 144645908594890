import './App.css';
import TopNav from './components/TopNav';
import { Routes, HashRouter, Route, Navigate } from 'react-router-dom';
import { Products, PasswordManager, API } from './Pages/Products';
import Footer from './components/footer';
import { Career, Collaborations, Learn, Teams, Support } from './Pages/Misc';

import { Home } from './Pages/home';

function App() { 
  return (
    <>
      <HashRouter>
      <TopNav></TopNav>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/Solutions' element={<Products/>}></Route>
          <Route path='Solutions/PasswordManager' element={<PasswordManager/>}></Route>
          <Route path='Solutions/StorageAPI' element={<API/>}></Route>
          <Route path='/Support' element={<Support/>}></Route>
          <Route path='Career' element={<Career/>}></Route>
          <Route path='Learn' element={<Learn/>}></Route>
          <Route path='Teams' element={<Teams/>}></Route>
          <Route path='Collaboration' element={<Collaborations/>}></Route>
          <Route path='*' element={<Navigate to={'/'}/>}></Route>
        </Routes>
      <Footer></Footer>
      </HashRouter>
      </>
  );
}

export default App;
