import './Support.css'
import './Misc.css'
import { ToTop, ImageCarousel, WordRoll, Accordion, SetTitle } from '../components/utility'
import brandlogo from '../static/enc.svg'

import CareerVector from '../static/banner-2-career.svg'
import TeamVector from '../static/banner-2-team.svg'
import CollabVector from '../static/banner-2-colab.svg'

import LearnVector from '../static/banner-2-learn.svg'
// import { NavLink } from 'react-router-dom'

import support_vector from '../static/banner-2-support.svg'
import { useEffect, useState } from 'react'
import { base } from './home'


// TabView




function Career() {
    const [Openings, UpdateOpenings] = useState([])
    const [Contacts, UpdateContacts] = useState([])
    useEffect(() => {
        var Url = base + 'DataPoint?' + (new URLSearchParams({ Data: 'Career' })).toString()
        fetch(Url).then(promise => {
            return (promise.json())
        }).then(response => {
            UpdateOpenings(response['Openings'])
            UpdateContacts(response['Contacts'])
        })
    }, [])

    function ContactsPopulate() {
        var contacts = []
        Contacts.forEach((element, i) => {
            const LinkPopulate = () => {
                var _links = []
                element.Links.forEach((element, i) => {
                    _links.push(
                        <div className="Info-Card_Body" key={i}>
                            <a target='_blank' rel='noreferrer' href={element['URL']}>{element['Text']}</a>
                        </div>
                    )
                })
                return _links
            }
            const Email = () => {
                if (element.Email !== null) {
                    return (
                        <div className="Info-Card_Body">
                            <a target='_blank' rel='noreferrer' href={'mailto:' + element['Email']}>{element['Email']}</a>
                        </div>
                    )
                }
                else {
                    return null
                }
            }

            contacts.push(
                <div key={i} className="Info-Card">
                    <h2 className="Info-Card_heading">
                        {element['Name']}
                    </h2>
                    <div className="Info-Card_Body">
                        {element['Designation']}
                    </div>
                    <Email></Email>
                    <LinkPopulate></LinkPopulate>
                </div>
            )
        });
        return contacts
    }
    SetTitle('Encrypta.in - Careers')

    return (
        <>
            <ToTop></ToTop>
            <div className="Product_Banner-container">
                <div className="banner-body">
                    <h1 className='Banner-title'>Career</h1>
                    <div className="Banner-content">
                        Career Opportunities at Encrypta.
                    </div>
                </div>
                <div className="banner-vector">
                    <ImageCarousel images={[CareerVector]}></ImageCarousel>
                </div>
            </div>
            <div className="Product_item-Content-Wrapper">
                <div className="Product_item-title">
                    <h1>
                        Teams
                    </h1>
                </div>
                <div className="Product_item-container">
                    <div className="Product_item-body">
                        <div className="Product_item-heading">
                            <h1>Find your Ideal Team.</h1>
                        </div>
                        We grow together. Learn, Build and Explore with your ideal Team and it's team is looking for you.
                    </div>
                    <div className="Product_item-vector">
                        <img src={TeamVector} className='vector' alt="" />
                    </div>
                </div>
            </div>
            <div className="Info-Card-Wrapper">
                <div className="Info-Card">
                    <h3 className="Info-Card_heading">
                        Engineering and Technology
                    </h3>
                    <div className="Info-Card_Body">
                        Visionary Products and Tools of the future are yet to be built. Maybe all it needs is you.
                    </div>
                </div>
                <div className="Info-Card">
                    <h3 className="Info-Card_heading">
                        Designing and Art
                    </h3>
                    <div className="Info-Card_Body">
                        Let your vision for the future be shown to the world. Be the presenter of your vision.
                    </div>
                </div>
                <div className="Info-Card">
                    <h3 className="Info-Card_heading">
                        Support, Sales and Service
                    </h3>
                    <div className="Info-Card_Body">
                        Support and Assistance are essential for a user to get equipped and utilize the tools adequately.
                    </div>
                </div>
                <div className="Info-Card">
                    <h3 className="Info-Card_heading">
                        Media and Marketing
                    </h3>
                    <div className="Info-Card_Body">
                        Be the face of Encrypta and convey the vision to the world.
                    </div>
                </div>
            </div>
            <div className="Product_item-Content-Wrapper">
                <div className="Product_item-title">
                    <h1>
                        Learn, Explore, Excel
                    </h1>
                </div>
                <div className="Product_item-container">
                    <div className="Product_item-body">
                        <div className="Product_item-heading">
                            <h1>Shape the Future as You Learn.</h1>
                        </div>
                        At Encrypta we believe, Your Strength, Your Excellence and Your Experience are what define us.
                        {/* <NavLink className="Product_item-link" to={'PasswordManager'}>
                            <button className="btn-main btn btn-1">Learn More</button>
                        </NavLink> */}
                    </div>
                    <div className="Product_item-vector">
                        <img src={LearnVector} className='vector' alt="" />
                    </div>
                </div>
            </div>
            <div className="Info-Card-Wrapper">
                <div className="Info-Card">
                    <h3 className="Info-Card_heading">
                        Learn
                    </h3>
                    <div className="Info-Card_Body">
                        Learn to make dreams meet reality.
                    </div>
                </div>
                <div className="Info-Card">
                    <h3 className="Info-Card_heading">
                        Explore
                    </h3>
                    <div className="Info-Card_Body">
                        Explore the Abilities within you.
                    </div>
                </div>
                <div className="Info-Card">
                    <h3 className="Info-Card_heading">
                        Build
                    </h3>
                    <div className="Info-Card_Body">
                        Build Visionary tools and solutions of the future.
                    </div>
                </div>
                <div className="Info-Card">
                    <h3 className="Info-Card_heading">
                        Excel
                    </h3>
                    <div className="Info-Card_Body">
                        Reinforce your Strengths and Overcome your Weaknesses.
                    </div>
                </div>
            </div>
            <div className="Product_item-Content-Wrapper">
                <div className="Product_item-title">
                    <h1>
                        Work culture
                    </h1>
                </div>
                <div className="Product_item-container">
                    <div className="Product_item-body">
                        <div className="Product_item-heading">
                            <h1>productivity with a touch of fun.</h1>
                        </div>
                        Cultivating Fun and productivity at Encrypta Workplace.
                    </div>
                    <div className="Product_item-illustration">
                        <h1>
                            {/* Shape */}
                            {/* <WordRoll seconds={5} direction='DOWN' WordObject={[{ Word: 'Conveniently', Color: '#f00' }, { Word: 'Productively', Color: '#00f' }, { Word: 'Actively', Color: '#0f0' }, { Word: 'Remotely', Color: '#5d5dff' }]}></WordRoll> */}
                            {/* At Encrypta. */}
                            <WordRoll seconds={3} direction='DOWN' WordObject={[{ Word: 'Learn', Color: '#f00' }, { Word: 'Explore', Color: '#00f' }, { Word: 'Build', Color: '#0f0' }, { Word: 'Excel', Color: '#5d5dff' }]}></WordRoll>
                            With Encrypta.
                        </h1>
                    </div>
                </div>
            </div>
            {/* <div className="Product_item-Content-Wrapper">
                <div className="Product_item-title">
                    <h1>
                        Locations
                    </h1>
                </div>
                <div className="Product_item-container">
                    <div className="Product_item-body">
                        <div className="Product_item-heading">
                            <h1>Location and Environment.</h1>
                        </div>
                        Productivity Depends on your environment and location. Significant Locations just to serve perfectly.
                    </div>
                    <div className="Product_item-illustration">
                        <h1>
                            Work
                            <WordRoll seconds={5} direction='DOWN' WordObject={[{ Word: 'National Institute of Technology, Rourkela', Color: '#f00', FontSize: '1.8rem'}, ]}></WordRoll>
                            At Encrypta.
                        </h1>
                    </div>
                </div>
            </div> */}
            <div className="Product_item-Content-Wrapper">
                <div className="Product_item-title">
                    <h1>
                        Openings
                    </h1>
                </div>
                <div className="Product_item-container">
                    <div className="Product_item-body">
                        <div className="Product_item-heading">
                            <h1>Are you ready to shape the future?</h1>
                        </div>
                        Team Encrypta is ready to solve the equation for a better future. Maybe are the missing variable?
                    </div>
                    <div className="Product_item-illustration">
                        <h1>
                            <WordRoll seconds={5} direction='DOWN' WordObject={[{ Word: 'Get Creative', Color: '#f00' }, { Word: 'Get Growing', Color: '#00f' }, { Word: 'Excel', Color: '#5d5dff' }, { Word: 'Explore', Color: '#f00' }, { Word: 'BE YOU', Color: '#0f0' },]}></WordRoll>
                            At Encrypta!
                        </h1>
                    </div>
                </div>
            </div>
            <div className="Info-Card-Wrapper">
                <div className="Info-Card" style={{ display: 'flex', textAlign: 'center', justifyContent: 'space-evenly' }}>
                    <h3 className="Info-Card_heading">
                        Designer
                    </h3>
                    <div className="Info-Card_Body">
                        {Openings['Designers']} openings
                    </div>
                </div>
                <div className="Info-Card" style={{ display: 'flex', textAlign: 'center', justifyContent: 'space-evenly' }}>
                    <h3 className="Info-Card_heading">
                        Developer
                    </h3>
                    <div className="Info-Card_Body">
                        {Openings['Developers']} openings
                    </div>
                </div>
                <div className="Info-Card" style={{ display: 'flex', textAlign: 'center', justifyContent: 'space-evenly' }}>
                    <h3 className="Info-Card_heading">
                        Sales, Service and support
                    </h3>
                    <div className="Info-Card_Body">
                        {Openings['Sales']} openings
                    </div>
                </div>
                <div className="Info-Card" style={{ display: 'flex', textAlign: 'center', justifyContent: 'space-evenly' }}>
                    <h3 className="Info-Card_heading">
                        Media and Marketing
                    </h3>
                    <div className="Info-Card_Body">
                        {Openings['Media']} openings
                    </div>
                </div>
                <div className="Info-Card" style={{ display: 'flex', textAlign: 'center', justifyContent: 'space-evenly' }}>
                    <h3 className="Info-Card_heading">
                        Research and Development
                    </h3>
                    <div className="Info-Card_Body">
                        {Openings['Research']} openings
                    </div>
                </div>
            </div>
            <div className="Product_item-Content-Wrapper">
                <div className="Product_item-title">
                    <h1>
                        Get in touch!
                    </h1>
                </div>
                <div className="Product_item-container">
                    <div className="Product_item-body">
                        <div className="Product_item-heading">
                            <h1>Join Us!</h1>
                        </div>
                        Get in contact with our Hiring and Management!
                    </div>
                    {/* <div className="Product_item-illustration">
                        <h1>
                            <WordRoll seconds={5} direction='DOWN' WordObject={[{ Word: 'Get in touch', Color: '#f00' }, { Word: 'Get Growing', Color: '#00f' }, { Word: 'Excel', Color: '#5d5dff' }, { Word: 'Explore', Color: '#f00' }, { Word: 'BE YOU', Color: '#0f0' },]}></WordRoll>
                            At Encrypta!
                        </h1>
                    </div> */}
                </div>
            </div>
            <div className="Info-Card-Wrapper" style={{ 'textAlign': 'center' }}>
                {/* <div className="Info-Card">
                    <h2 className="Info-Card_heading">
                        Aditya Narayan Patro
                    </h2>
                    <div className="Info-Card_Body">
                        Designation
                    </div>
                    <div className="Info-Card_Body">
                        emailId
                    </div>
                </div>
                <div className="Info-Card">
                    <h2 className="Info-Card_heading">
                        Akshita Sahu
                    </h2>
                    <div className="Info-Card_Body">
                        Designation
                    </div>
                    <div className="Info-Card_Body">
                        email ID
                    </div>
                </div> */}
                <ContactsPopulate></ContactsPopulate>
            </div>
        </>
    )
}


function Learn() {
    return (
        <>
            {/* <ToTop></ToTop> */}
            <div className="Product_Banner-container">
                <div className="banner-body">
                    <h1 className='Banner-title'>
                        Learn With Encrypta.
                    </h1>
                    <div className="Banner-content">
                        Learning Opportunities at Encrypta.
                    </div>
                </div>
                <div className="banner-vector">
                    <ImageCarousel images={[LearnVector]}></ImageCarousel>
                </div>
            </div>
            <div className="Product_item-Content-Wrapper">
                <div className="Product_item-title">
                    <h1>
                        Be the ONE standing out from the crowd.
                    </h1>
                </div>
                <div className="Product_item-container">
                    <div className="Product_item-body">
                        <div className="Product_item-heading">
                            <h1>Unleash your curiosity.</h1>
                        </div>
                        Expand your horizons and embrace the joy of learning. Unleash your curiosity and dive into a world of knowledge that will fuel your personal and professional growth.
                    </div>
                    <div className="Product_item-illustration">
                        <h1>
                            <WordRoll seconds={4} direction='DOWN' WordObject={[{ Word: 'Programming', Color: '#f00' }, { Word: 'Development', Color: '#00f' }, { Word: 'Designing', Color: '#0f0' }, { Word: 'Ethics', Color: '#5d5dff' }, { Word: 'Philosophy', Color: '#f00' }, { Word: 'Dream', Color: '#00f' }, { Word: 'Coding', Color: '#0f0' }, { Word: 'Writing', Color: '#5d5dff' }]}></WordRoll>
                            At Encrypta.
                        </h1>
                    </div>
                </div>
            </div>
            <div className="Product_item-Content-Wrapper">
                <div className="Product_item-title">
                    <h1>
                        Discover new territories.
                    </h1>
                </div>
                <div className="Product_item-container">
                    <div className="Product_item-body">
                        <div className="Product_item-heading">
                            <h1>ignite your passion for learning.</h1>
                        </div>
                        Embark on a journey of exploration as you venture into uncharted territories. Step out of your comfort zone and explore experiential learning opportunities that take you beyond the conventional approach.
                        {/* Self-Exploration is the key to archive Excellence and Confidence. */}
                    </div>
                    <div className="Product_item-illustration">
                        <h1>
                            <WordRoll seconds={4} direction='DOWN' WordObject={[{ Word: 'Step Out', Color: '#f00' }, { Word: 'Explore', Color: '#00f' }, { Word: 'Learn', Color: '#0f0' }, { Word: 'Build', Color: '#5d5dff' }]}></WordRoll>
                            With Encrypta.
                        </h1>
                    </div>
                </div>
            </div>
            <div className="Product_item-Content-Wrapper">
                <div className="Product_item-title">
                    <h1>
                        Learn from the best
                    </h1>
                </div>
                <div className="Product_item-container">
                    <div className="Product_item-body">
                        <div className="Product_item-heading">
                            <h1>Learn from the Do-ers.</h1>
                        </div>
                        Learn from the Ones who believed in bringing a change. Where Innovation, will power, experience combines.
                    </div>
                    <div className="Product_item-illustration">
                        {/* <h1>
                            <WordRoll seconds={4} direction='DOWN' WordObject={[{ Word: 'Step Out', Color: '#f00' }, { Word: 'Explore', Color: '#00f' }, { Word: 'Learn', Color: '#0f0' }, { Word: 'Build', Color: '#5d5dff' }]}></WordRoll>
                            With Encrypta.
                        </h1> */}
                        <img src={LearnVector} alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

function Teams() {
    return (
        <>
            <ToTop></ToTop>
            <div className="Product_Banner-container">
                <div className="banner-body">
                    <div className="logo-container">
                        <object type="image/svg+xml" data={brandlogo} className="brand-logo">
                            Encrypta.in
                        </object>
                    </div>
                    <h1 className='Banner-title'>
                        Faces Behind Encrypta.
                    </h1>
                    <div className="Banner-content">
                        Faces who made and making encrypta touching heights.
                    </div>
                </div>
                <div className="banner-vector">
                    <ImageCarousel images={[TeamVector]}></ImageCarousel>
                </div>
            </div>
        </>
    )
}


function Collaborations() {
    return (
        <>
            <ToTop></ToTop>
            <div className="Product_Banner-container">
                <div className="banner-body">
                    <h1 className='Banner-title'>
                        Collaborate With Encrypta.
                    </h1>
                    <div className="Banner-content">
                        Learning Opportunities at Encrypta.
                    </div>
                </div>
                <div className="banner-vector">
                    <ImageCarousel images={[CollabVector]}></ImageCarousel>
                </div>
            </div>

        </>
    )
}

function Onfocus(e) {
    var element = e.target
    element.classList.add('input-focused')
}

function Onblur(e) {
    var element = e.target
    if (element.value.length === 0) {
        element.classList.remove('input-focused')
    }
}

function Support() {

    SetTitle('Encrypta.in - Support')
    const [Docs, UpdateDocs] = useState([])
    const [FAQ, UpdateFAQ] = useState([])
    const [Notifications, UpdateNotifications] = useState([])

    useEffect(() => {
        var Url = base + 'DataPoint?' + (new URLSearchParams({ Data: 'Support' })).toString()
        fetch(Url).then(promise => {
            return (promise.json())
        }).then(response => {
            UpdateDocs(response['Docs'])
            UpdateFAQ(response['FAQ'])
        })
    }, [])
    function DocsPopulate() {
        var doc = []
        Docs.forEach((element, i) => {
            doc.push(
                <a href={element.Link} rel='noreferrer' target='_blank' key={'document-' + i} className='Releases-Container'>
                    <div className="release-face">
                        {element.Name}
                    </div>
                    <div className="release-marker">
                        <i className="fa fa-download"></i>
                    </div>
                </a>
            )
        });
        return doc
    }
    function AddNotification(notification, type) {
        var _id = (Notifications.length + 1)
        UpdateNotifications([...Notifications, { ID: _id, Notify: true, Type: type, Notification: notification }])
        setTimeout(() => {
            ShowNotification(Notifications, _id)
        }, (1 / 4) * 1000);
        setTimeout(() => {
            HideNotification(_id)
        }, 6 * 1000);
    }
    function ShowNotification(notifications, id) {
        var element = document.querySelector(`.Notification[data-notification-id='${id}']`)
        element.classList.add('Show')
    }
    function HideNotification(id) {
        // var element = document.querySelector(`.Notification.Show[data-notification-id='${id}']`)
        var element = document.querySelector(`.Notification[data-notification-id='${id}']`)
        // element.classList.replace('Show', 'Hide')
        element.classList.replace('Show', 'Hide')
        // console.log(element)
        setTimeout(() => {
            const _Notifications = Notifications.filter(notification => notification.ID !== id)
            UpdateNotifications(_Notifications)
        }, 10000);
    }

    function FAQPopulate() {
        var _FAQ = []
        if (FAQ.length === 0) {
            _FAQ.push(
                <div style={{ 'fontSize': '1.2rem', margin: '0.8rem auto', textAlign: 'center' }}>
                    <h2>Oops! No FAQs Answered.</h2>
                </div>
            )
        }
        FAQ.forEach((element, i) => {
            _FAQ.push(
                <Accordion key={'FAQ-' + i} Head={element.Head} Body={element.Body}></Accordion>
            )
        });
        return _FAQ
    }


    function Notification() {
        return (
            <div className='Notification-wrapper'>
                {(Notifications.map((notification, i) => {
                    return (
                        <div key={'Notification-' + i} className={`Notification`} data-notification-type={notification.Type} data-notification-id={notification.ID} dangerouslySetInnerHTML={{ __html: notification.Notification }}></div>
                        // <div key={'Notification-' + i} className={`Notification Show`} data-notification-id={notification.ID} dangerouslySetInnerHTML={{ __html: notification.Notification }}></div>
                        // <div key={'Notification-' + i} className={`Notification ${notification.Notify ? 'Show' : 'Hide'}`} data-notification-id={notification.ID} dangerouslySetInnerHTML={{ __html: notification.Notification }}></div>
                    )
                }))}
            </div>
        )
    }

    function ReportBug(e) {
        var Url = base + 'Report'
        // fetch(Url, { method: 'POST', body: JSON.stringify({ "Product": e.target.product.value, 'Issue': e.target.Issue.value })}).then(promise => {
        fetch(Url, { method: 'POST', body: JSON.stringify({ "Product": e.target.product.value, 'Email': e.target.email.value, 'Issue': e.target.Issue.value }) }).then(promise => {
            if (promise.status === 0) {
                AddNotification('<div><strong>Check your internet connection!</strong></div><div>Seems that you are offline</div>', 'error')
            }
            else {
                return promise.json()
            }
        }).then(response => {
            // console.log(response['ServerResponse'])
            AddNotification('<div><strong>Thank you for raising the issue!</strong></div><div>We\'ll reach you back soon.</div>', 'success')
            e.target.reset()
            e.target.product.value = ''
        }).catch((error) => {
            // console.log(error)
        })
        e.preventDefault()
    }
    return (
        <>
            <ToTop></ToTop>
            <div className="Product_Banner-container">
                <div className="banner-body">
                    <h1 className='Banner-title'>Support</h1>
                    <div className="Banner-content">
                        Got Something in your head? We are here to help.
                    </div>
                </div>
                <div className="banner-vector">
                    <ImageCarousel images={[support_vector]}></ImageCarousel>
                </div>
            </div>
            <div className="Product_item-Content-Wrapper">
                <div className="Product_item-title">
                    <h1>
                        Frequently Asked Questions
                    </h1>
                </div>
                <FAQPopulate />
            </div>
            <div className="Product_item-Content-Wrapper">
                <div className="Product_item-title">
                    <h1>
                        Documentation
                    </h1>
                </div>
                <DocsPopulate />
            </div>
            <div className="Product_item-Content-Wrapper">
                <div className="Product_item-title">
                    <h1>
                        Facing Issue?
                    </h1>
                </div>
                <form onSubmit={ReportBug}>
                    {/* <div className="form-item-wrapper">
                        <div className="input-wrap">
                            <div className="input-container">
                                <input type='text' id="" className="input-element" onFocus={Onfocus} onBlur={Onblur} name="" />
                                <div className="placeholder">Name</div>
                            </div>
                        </div>
                    </div> */}
                    <div className="form-item-wrapper">
                        <div className="wrap">
                            <div className="select">
                                <select className="select-text" id="" name="product" required>
                                    <option value="" data-type="default" selected disabled></option>
                                    <option value="password-manager">Password Manager</option>
                                    <option value="Sign-In">Signing In to your account</option>
                                    <option value="Sign-Up">Creating Account</option>
                                    {/* <option value="API">Signing Up</option> */}
                                </select>
                                <span className="select-bar"></span>
                                <label className="select-label">Facing Issue in</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-item-wrapper">
                        <div className="input-wrap" data-container-type="h-3r">
                            <div class="input-container">
                                <input type='email' required class="input-element" onFocus={Onfocus} onBlur={Onblur} name="email" />
                                <div class="placeholder">E-Mail</div>
                            </div>
                        </div>
                    </div>
                    <div className="form-item-wrapper">
                        <div className="input-wrap" data-container-type="h-7r">
                            <div className="textarea-container">
                                <textarea type='text' required minLength={10} className="textarea-element" onFocus={Onfocus} onBlur={Onblur} name="Issue"></textarea>
                                <div className="placeholder">Describe Your Issue</div>
                            </div>
                        </div>
                    </div>
                    <div className="form-item-wrapper">
                        <button className="btn-main btn btn-1" type='submit'>Report</button>
                    </div>
                    <Notification></Notification>
                </form >
            </div>
        </>
    )
}



export { Career, Learn, Teams, Collaborations, Support, }