import './TopNav.css'
import brandlogo from '../static/enc.svg'
import { NavLink } from 'react-router-dom'

import { useState, useEffect } from 'react';
import { base } from '../Pages/home';


function Screen(w) {
    return window.matchMedia(`(max-width: ${w}px)`).matches
}


function Profile() {
    const [Profile, UpdateProfile] = useState({})
    useEffect(() => {
        var Url = base + 'UserProfile'
        fetch(Url).then(promise => {
            return (promise.json())
        }).then(response => {
            UpdateProfile(response['ServerResponse'])
        })
    }, [])
    function _UserName() {
        // console.log(Profile)
        if (Screen(500)) {
            return (
                <div className="dropdown-item">
                    {Profile.UserName}
                </div>
            )
        }
        else {
            return (
                <></>
            )
        }
    }
    var UserDetails = Profile
    if (UserDetails !== null) {
        return (
            <div className="profile-container">
                <div className="dropdown">
                    <div className="dropdown-marker">
                        {Screen(500) && UserDetails.UserName !== undefined ? UserDetails.UserName[0] : UserDetails.UserName}
                    </div>
                    <div className="dropdown-content">
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        <_UserName></_UserName>
                        <a href="http://accounts.encrypta.in/Account" className="dropdown-item">
                            My Account
                        </a>
                        <a className="dropdown-item" href="http://accounts.encrypta.in/SignOut">
                            {/* <span> */}
                            <i className="fa-power-off fa"></i>
                            <div className="link-text">
                                Logout
                            </div>
                            {/* </span> */}
                        </a>
                    </div >
                </div >
            </div >
        )
    }
    else {
        return (

            <a  href="http://accounts.encrypta.in">
                <button className="btn btn-main btn-1">
                    Sign In
                </button>
            </a>
        )
    }
}



function TopNav() {
    const [NavState, UpdateNav] = useState(false)
    function ToggleNav(){
        UpdateNav(!NavState)
    }
    return (
        <div className={`top-nav_container ${NavState? 'Visible': 'Hidden'}` }>
            <button className="hamburger-wrapper" onClick={ToggleNav}>
                <i className={NavState?'fa fa-times': 'fa fa-bars'}></i>
            </button>
            <div className="nav-section" data-section='Brand-Section'>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" className="logo-container">
                    <object type="image/svg+xml" data={brandlogo} className="brand-logo">
                        Encrypta.in
                    </object>
                </a>
            </div>
            <div className="nav-section" data-section='Link-Section'>
                <NavLink to={'/Career'} onClick={ToggleNav} className="nav-link">
                    Career
                </NavLink>
                {/* <NavLink to={'/About'} className="nav-link" href='/'>
                    About
                </NavLink> */}
                <NavLink to={'/Support'} onClick={ToggleNav} className="nav-link">
                    Support
                </NavLink>
                <NavLink to={'/Solutions'} onClick={ToggleNav} className="nav-link" >
                    Solutions
                </NavLink>
                {/* <NavLink to={'/About'} className="nav-link" href='/'>
                    link
                </NavLink> */}
            </div >
            <div className="nav-section" data-section='Profile-Section'>
                <Profile></Profile>
            </div>
        </div >
    )
}

export default TopNav;