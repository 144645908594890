import { useEffect, useState, useRef } from 'react';
import './utility.css'
import { NavLink } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';


// function View(){
//     return current
// }

function ImageCarousel({ seconds, images, control = false, marker = false }) {
    function Next() {
        var index = CarouselCurrentState.indexOf('show')
        CarouselCurrentState.splice(index, 1)
        if (index === CarouselCurrentState.length) {
            index = -1
        }
        CarouselCurrentState.splice(index + 1, 0, 'show')
        return CarouselCurrentState
    }

    function Previous() {
        var index = CarouselCurrentState.indexOf('show')
        CarouselCurrentState.splice(index, 1)
        if (index === 0) {
            index = CarouselCurrentState.length + 1
        }
        CarouselCurrentState.splice(index - 1, 0, 'show')
        return CarouselCurrentState
    }
    var CurrentCarousalState = []
    for (let i = 0; i < images.length; i++) {
        CurrentCarousalState.push(i === 0 ? 'show' : 'hide')
    }
    const [CarouselCurrentState, UpdateCurrent] = useState(CurrentCarousalState)
    const [Iteration, UpdateIteration] = useState(0)
    var carousel = []
    var CarouselMarker = []
    images.forEach((element, i) => {
        carousel.push(
            // <img src={element} data-iteration={Iteration} key={'carousel-item-' + i} data-carousel-item={(CarouselCurrentState[i])} className={'carousel-item'} alt="" />
            <img src={element} key={'carousel-item-' + i} data-carousel-item={(CarouselCurrentState[i])} className={'carousel-item'} alt="" />
        )
        if (marker) {
            CarouselMarker.push(
                <div className={"marker"} key={'carousel-marker-' + i} data-item-state={CarouselCurrentState[i]}></div>
            )
        }
    });
    useEffect(() => {
        if (seconds !== undefined) {
            const interval = setInterval(() => {
                var LatestCarouselCurrentState = Next()
                // var LatestCarouselCurrentState = Previous()
                UpdateCurrent(LatestCarouselCurrentState)
            }, seconds * 1000);
            const itterinterval = setInterval(() => {
                UpdateIteration(Iteration + 1)
            }, seconds * 1000);
            return (() => {
                clearInterval(interval)
                clearInterval(itterinterval)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Next, Previous, seconds])
    return (
        <div className="carousel-wrap">
            {carousel}
            <div className="marker-wrapper">
                {/* <div className="control-mark">
                    <i className="fa fa-angle-left"></i>
                </div> */}
                {CarouselMarker}
                {/* <button className="control-mark">
                    <i className="fa fa-angle-right"></i>
                </button> */}
            </div>
        </div>
    )
}


function ContainerCarousel({ seconds, Elements, control = false, marker = false, link }) {
    function Next() {
        var index = CarouselCurrentState.indexOf('show')
        CarouselCurrentState.splice(index, 1)
        if (index === CarouselCurrentState.length) {
            index = -1
        }
        CarouselCurrentState.splice(index + 1, 0, 'show')
        return CarouselCurrentState
    }
    function Previous() {
        var index = CarouselCurrentState.indexOf('show')
        CarouselCurrentState.splice(index, 1)
        if (index === 0) {
            index = CarouselCurrentState.length + 1
        }
        CarouselCurrentState.splice(index - 1, 0, 'show')
        return CarouselCurrentState
    }
    var CurrentCarousalState = []
    for (let i = 0; i < Elements.length; i++) {
        CurrentCarousalState.push(i === 0 ? 'show' : 'hide')
    }
    const [CarouselCurrentState, UpdateCurrent] = useState(CurrentCarousalState)
    const [Iteration, UpdateIteration] = useState(0)
    var carousel = []
    var CarouselMarker = []
    Elements.forEach((element, i) => {
        carousel.push(
            <div className="carousel-item" key={'carousel-item-' + i} data-carousel-item={(CarouselCurrentState[i])}>
                <div className="carousel-body">
                    <h1>{element.Body.Title}</h1>
                    <div className="carousel-body-content-container" dangerouslySetInnerHTML={{ __html: element.Body.Body }}>
                    </div>
                    {element.Body.Link !== undefined ? <NavLink className="carousel-link" to={element.Body.Link.URL}>
                        <button className="btn-main btn btn-1">{element.Body.Link.Text}</button>
                    </NavLink> : null}
                </div>
                <img src={element.Vector} className={'carousel-vector'} alt="" />
            </div>
        )
        if (marker) {
            CarouselMarker.push(
                <div className={"marker"} key={'carousel-marker-' + i} data-item-state={CarouselCurrentState[i]}></div>
            )
        }
    });
    useEffect(() => {
        if (seconds !== undefined) {
            const interval = setInterval(() => {
                var LatestCarouselCurrentState = Next()
                // var LatestCarouselCurrentState = Previous()
                UpdateCurrent(LatestCarouselCurrentState)
            }, seconds * 1000);
            const itterinterval = setInterval(() => {
                UpdateIteration(Iteration + 1)
            }, seconds * 1000);
            return (() => {
                clearInterval(interval)
                clearInterval(itterinterval)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Next, Previous, seconds])
    return (
        <div className="carousel-wrap">
            {carousel}
            <div className="marker-wrapper">
                {CarouselMarker}
            </div>
        </div>
    )
}




function ToTop() {
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    onTop()
    return null;
}


function SetTitle(Title, Mount = false){
    const defaultTitle = useRef(document.title);

    useEffect(()=>{
        document.title = Title
    }, [Title])

    useEffect(()=>{
        if (Mount){
            document.title = defaultTitle.current;
        }
    }, [Mount])
}


function WordRoll({ WordObject, seconds, direction = 'DOWN' }) {
    function Next() {
        var index = WordRollCurrentState.indexOf('show')
        WordRollCurrentState.splice(index, 1)
        if (index === WordRollCurrentState.length) {
            index = -1
        }
        WordRollCurrentState.splice(index + 1, 0, 'show')
        WordRollState = SetHidden()
        return WordRollState
    }
    function Previous() {
        var index = WordRollCurrentState.indexOf('show')
        WordRollCurrentState.splice(index, 1)
        if (index === 0) {
            index = WordRollCurrentState.length + 1
        }
        WordRollCurrentState.splice(index - 1, 0, 'show')
        WordRollState = SetHidden()
        return WordRollState
    }
    function SetHidden() {
        if (WordRollCurrentState.length > 3) {
            var index = WordRollCurrentState.indexOf('show')
            var PrevIndex
            var NextIndex
            if (index === WordRollCurrentState.length - 1) {
                PrevIndex = index - 1
                NextIndex = 0
            }
            else if (index === 0) {
                PrevIndex = WordRollCurrentState.length - 1
                NextIndex = index + 1
            }
            else {
                PrevIndex = index - 1
                NextIndex = index + 1
            }
            WordRollCurrentState[PrevIndex] = 'hide-p'
            WordRollCurrentState[NextIndex] = 'hide-n'
        }
        return WordRollCurrentState
    }
    var WordRollState = []
    for (let i = 0; i < WordObject.length; i++) {
        WordRollState.push(i === 0 ? 'show' : 'hide')
    }
    const [WordRollCurrentState, UpdateCurrent] = useState(WordRollState)
    const [Iteration, UpdateIteration] = useState(0)

    var WordRoll = []
    SetHidden()
    WordObject.forEach((element, i) => {
        WordRoll.push(
            <span className={'WordRoll-item'} data-wordroll-direction={direction} key={'WordRoll-item-' + i} data-iteration={Iteration} data-wordroll-state={(WordRollCurrentState[i])} style={{ 'color': element.Color, 'fontSize': element.FontSize }}>{element.Word}</span>
        )
    });
    useEffect(() => {
        if (seconds !== undefined) {
            const interval = setInterval(() => {
                var LatestWordRollCurrentState = Next()
                // var LatestWordRollCurrentState = Previous()
                UpdateCurrent(LatestWordRollCurrentState)
                // console.log(LatestWordRollCurrentState)

            }, seconds * 1000);
            const itterinterval = setInterval(() => {
                UpdateIteration(Iteration + 1)
            }, seconds * 1000);
            return (() => {
                clearInterval(interval)
                clearInterval(itterinterval)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Next, Previous, seconds])
    return (
        <div className="WordRoll-wrap">
            {WordRoll}
        </div>
    )
}


function Accordion({ Head, Body }) {
    const [Collapse, UpdateCollapseState] = useState(true)
    function ToggleState() {
        UpdateCollapseState(!Collapse)
    }
    return (
        // <div className={'Accordion ' + Collapse ? 'Collapsed': 'Hidden'}>
        <div className={`Accordion ${Collapse ? 'Collapsed' : 'Expanded'}`} >
            <div className="Accordion-head" onClick={ToggleState}>
                <div className="Accordion-face">
                    <h3>
                        {Head}
                    </h3>
                </div>
                <div className="Accordion-marker">
                    <i className={`fa fa-angle-${Collapse ? 'down' : 'up'}`}></i>
                </div>
            </div>
            <div className="Accordion-body" dangerouslySetInnerHTML={{ __html: Body }}>
            </div>
        </div>
    )
}



function ShowCase({ Title, Content, Image }) {
    return (
        <div className="ShowCase_container">
            <div className="ShowCase_Content">
                <div className="ShowCase_Face">
                    <h1>
                        {Title}
                    </h1>
                </div>
                <div className="ShowCase_Body" dangerouslySetInnerHTML={{ __html: Content }}></div>
            </div>
            <div className="vector-container">
                <img src={Image} className="vector" alt="" />
            </div>
        </div>
    )
}




// function Notification() {

//     const [Notifications, UpdateNotifications] = useState([])

//     function AddNotification(notification, type) {
//         var _id = (Notifications.length + 1)
//         UpdateNotifications([...Notifications, { ID: _id, Notify: true, Type: type, Notification: notification }])
//         console.log(Notifications)
//         setTimeout(() => {
//             HideNotification(_id)
//         }, 6 * 1000);
//     }

//     function HideNotification(id) {
//         var element = document.querySelector(`.Notification.Show[data-notification-id='${id}']`)
//         element.classList.replace('Show', 'Hide')
//         console.log(element)
//     }

//     return (
//         <>
//             {(Notifications.map((notification, i) => {
//                 return (
//                     <div key={'Notification-' + i} className={`Notification ${notification.Notify ? 'Show' : 'Hide'}`} data-notification-id={notification.ID} dangerouslySetInnerHTML={{ __html: notification.Notification }}></div>
//                 )
//             }))}
//         </>
//     )
// }





export { ImageCarousel, ContainerCarousel, ToTop, ShowCase, WordRoll, Accordion, SetTitle }